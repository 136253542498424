import type { ThemeConfig } from '@chakra-ui/react'
import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react'

import { AlertsTheme } from './alerts.theme'
import { ButtonTheme } from './button.theme'
import { ColorsTheme } from './colors.theme'
import { fontFamily } from './font-family'
import { styles } from './styles'
import { TabsTheme } from './tabs.theme'
import { TooltipsTheme } from './tooltips.theme'

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

export const theme = extendTheme(
  {
    config,

    fonts: {
      body: fontFamily,
      heading: fontFamily,
    },

    colors: ColorsTheme,
    styles,
    shadows: {
      'text-protect': '0px 1px 3px rgba(0, 0, 0, 0.7)',
    },

    layerStyles: {
      contentBox: {
        bg: 'bg.light',
        borderRadius: 'lg',
        boxShadow: 'md',
        padding: 6,
        '.chakra-ui-dark &': {
          bg: 'bg.dark',
          boxShadow: 'dark-lg',
        },
      },
    },

    components: {
      Alert: AlertsTheme,
      Button: ButtonTheme,
      Tabs: TabsTheme,
      Tooltip: TooltipsTheme,
    },
  },
  withDefaultColorScheme({ colorScheme: 'pink' })
)
