import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import { ChakraProvider, ColorMode, ColorModeScript } from '@chakra-ui/react'

import { initAmplify } from '@/app/contexts/initiateAmplify'
import { AppEnvProvider } from '@/app/environment/AppEnvProvider'
import { getAppEnv } from '@/app/environment/getAppEnv'
import type { ViteEnv } from '@/app/environment/types/ViteEnv'
import { App } from '@/ui/App'
import { init18n } from '@/ui/i18n'
import { theme } from '@/ui/theme'

import { reportWebVitals } from './reportWebVitals'

init18n()

const ENV = getAppEnv(import.meta.env as ViteEnv)
const { aws, authentication } = ENV
initAmplify(aws, authentication)

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const { initialColorMode } = theme.config

ReactDOM.render(
  <React.StrictMode>
    <ColorModeScript initialColorMode={initialColorMode as ColorMode} />
    <AppEnvProvider appEnv={ENV}>
      <ChakraProvider resetCSS theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ChakraProvider>
    </AppEnvProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
