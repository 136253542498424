import type { FC } from 'react'
import { useMemo } from 'react'

import classNames from 'classnames'
import type { BoxProps } from '@chakra-ui/react'
import { Box, Flex, Text } from '@chakra-ui/react'

import './NoteCard.styles.css'

import { useAppState } from '@/app/contexts/app/AppContext'
import { AppActionType } from '@/app/contexts/app/types/AppContext'
import { ScheduleEvent } from '@/app/models/ScheduleEvent'
import { TooltipWithArrow } from '@/ui/common/components/tooltip/TooltipWithArrow'

export type NoteCardProps = Omit<BoxProps, 'backgroundColor' | 'onClick'> & {
  note: ScheduleEvent
}

export const NoteCard: FC<NoteCardProps> = ({ note, ...props }) => {
  const { dispatch, state } = useAppState()

  const backgroundColor = useMemo(() => note.style?.backgroundColor ?? 'gray.500', [note.style])

  const tooltip = useMemo(() => <Text as='small'>{note.comment}</Text>, [note.comment])

  const isSelected = useMemo(() => state.selectedScheduleEvent?.id === note.id, [note.id, state.selectedScheduleEvent])

  return (
    <Box
      className={classNames('note-card', { 'note-card--selected': isSelected })}
      backgroundColor={backgroundColor}
      {...props}
      onClick={() => dispatch({ type: AppActionType.SetScheduleEvent, scheduleEvent: note })}
    >
      <Box className='note-card--subtype' backgroundColor={note.style?.bandColor ?? backgroundColor} />
      <TooltipWithArrow label={tooltip} openDelay={500} placement='top' lineHeight={1.15} pt={1} pb={2}>
        <Flex className='note-card--content' justify='space-between' gap={1}>
          <Box minW={0} flex={1}>
            <Flex wrap='wrap' justify='flex-start'>
              {note.comment && <Text fontSize='xs'>{note.comment}</Text>}
            </Flex>
          </Box>
        </Flex>
      </TooltipWithArrow>
    </Box>
  )
}
