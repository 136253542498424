import { getDateAtStartOfHour } from '@/app/helpers/time'
import { SchedulerStep } from '@/ui/common/components/scheduler/types/Scheduler'

export const getNumberOfStepsFromDayStart = (eventStart: Date, dayStart: Date, step: SchedulerStep): number => {
  const floorMin = getDateAtStartOfHour(dayStart)
  return Math.floor((eventStart.getTime() - floorMin.getTime()) / 1000 / 60 / step)
}

export const getNumberOfStepsSpan = (start: Date, end: Date, step: SchedulerStep): number => {
  return (end.getTime() - start.getTime()) / 1000 / 60 / step
}
