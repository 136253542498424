import { AppEnv } from '@/app/environment/types/AppEnv'
import { MissingViteEnvError } from '@/app/environment/types/MissingViteEnvError'
import { ViteEnv } from '@/app/environment/types/ViteEnv'

export const getAppEnv = (viteEnv: ViteEnv): AppEnv => {
  const env: AppEnv['env'] = viteEnv.MODE

  const apiEnvVars: Record<keyof AppEnv['api'], string | undefined> = {
    baseURL: viteEnv.VITE_API_BASE_URL,
  }

  const awsEnvVars: Record<keyof AppEnv['aws'], string | undefined> = {
    region: viteEnv.VITE_AWS_REGION,
  }

  const authenticationEnvVars: Record<keyof AppEnv['authentication'], string | undefined> = {
    userPoolId: viteEnv.VITE_AWS_USER_POOL_ID,
    userPoolWebClientId: viteEnv.VITE_AWS_USER_POOL_WEB_CLIENT_ID,
    oauthDomain: viteEnv.VITE_AWS_OAUTH_DOMAIN,
    oauthRedirectSignIn: viteEnv.VITE_AWS_OAUTH_REDIRECT_SIGN_IN,
    oauthRedirectSignOut: viteEnv.VITE_AWS_OAUTH_REDIRECT_SIGN_OUT,
  }

  Object.entries({ ...awsEnvVars, ...authenticationEnvVars, ...apiEnvVars }).forEach(([envVarName, envVarValue]) => {
    if (!envVarValue) {
      throw new MissingViteEnvError(envVarName)
    }
  })
  return {
    env,
    aws: awsEnvVars as AppEnv['aws'],
    authentication: authenticationEnvVars as AppEnv['authentication'],
    api: apiEnvVars as AppEnv['api'],
  }
}
