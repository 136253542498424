import { FC } from 'react'

import { ScheduleEvent } from '@/app/models/ScheduleEvent'
import { EmptyScheduleEventDetails } from '@/ui/common/components/scheduleEventDetails/components/EmptyScheduleEventDetails'
import { FilledAppointmentDetails } from '@/ui/common/components/scheduleEventDetails/components/FilledAppointmentDetails'
import { NoteDetails } from '@/ui/common/components/scheduleEventDetails/components/NoteDetails'
import { isAppointment } from '@/ui/common/typeGuard/scheduleEventTypeGuard'

type AppointmentDetailsProps = {
  scheduleEvent?: ScheduleEvent
}
export const ScheduleEventDetails: FC<AppointmentDetailsProps> = ({ scheduleEvent }) => {
  if (isAppointment(scheduleEvent)) {
    return <FilledAppointmentDetails appointment={scheduleEvent} />
  }
  if (scheduleEvent) {
    return <NoteDetails note={scheduleEvent} />
  }
  return <EmptyScheduleEventDetails />
}
