import { useMemo } from 'react'

import { getNumberOfStepsFromDayStart, getNumberOfStepsSpan } from '@/ui/common/components/scheduler/helpers'
import { useSteps } from '@/ui/common/components/scheduler/hooks/useSteps'
import type { GenericCard, GenericEvent, SchedulerStep } from '@/ui/common/components/scheduler/types/Scheduler'

type UsagesPerStep<E extends GenericEvent> = { usages: number[]; cards: GenericCard<E>[] }

export function useUsagesPerStep<E extends GenericEvent>(
  min: Date,
  max: Date,
  events: E[],
  step: SchedulerStep
): UsagesPerStep<E> {
  const steps = useSteps(min, max, step)
  const dayStart: Date = useMemo(() => steps[0]?.start, [steps])

  return useMemo((): UsagesPerStep<E> => {
    const usages = Array.from({ length: steps.length }).fill(0) as number[]
    const cards: GenericCard<E>[] = []

    // Step 1 + 2: Count how much Appointments in each step + Make Cards out of Events:
    events.forEach((event) => {
      const topStep = getNumberOfStepsFromDayStart(event.start, dayStart, step)
      const heightSteps = getNumberOfStepsSpan(event.start, event.end, step)
      const bottomStep = heightSteps + topStep

      for (let x = Math.max(0, Number(topStep)); x < bottomStep; x += 1) {
        usages[x] += 1
      }

      let position = 0
      for (let x = Math.max(0, Number(topStep)); x < bottomStep; x += 1) {
        if (usages[x]) {
          position = Math.max(position, usages[x])
        }
      }

      cards.push({
        ...event,
        position,
        of: position,
        topStep,
        heightSteps,
      })
    })

    // Step 3: Set Card property “of”:
    events.forEach((event, index) => {
      const topStep = getNumberOfStepsFromDayStart(event.start, dayStart, step)
      const bottomStep = getNumberOfStepsSpan(event.start, event.end, step) + topStep
      let positionTo = 1
      for (let x = Math.max(0, Number(topStep)); x < bottomStep; x += 1) {
        if (usages[x]) {
          positionTo = Math.max(positionTo, usages[x])
        }
      }

      cards[index].of = positionTo
    })

    return { usages, cards }
  }, [steps.length, events, dayStart, step])
}
