import { useQuery, UseQueryResult } from 'react-query'

import axios, { AxiosError } from 'axios'
import { ClinicDto } from '@dentitek-cloud/shared/dtos/clinic/clinicDto'

import { toClinic } from '@/app/api/mappers/clinic/clinicMappers'
import { useFreshToken, useUser } from '@/app/contexts/auth/AuthContext'
import { useAppEnv } from '@/app/environment/AppEnvProvider'
import { useErrorToast } from '@/app/hooks/useErrorToast'
import { Clinic } from '@/app/models/Clinic'

export const useClinics = (): UseQueryResult<Clinic[] | undefined> => {
  const user = useUser()
  const freshTokenGetter = useFreshToken()
  const showErrorToast = useErrorToast()

  const {
    api: { baseURL },
  } = useAppEnv()

  return useQuery(
    'clinics',
    async (): Promise<Clinic[] | undefined> => {
      if (!user) {
        return undefined
      }

      const token = await freshTokenGetter()

      const httpClient = axios.create({
        baseURL,
      })

      const response = await httpClient.get<ClinicDto[]>('/clinics', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return response.data.map((clinicDto) => toClinic(clinicDto))
    },
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      onError: (err: AxiosError) => {
        showErrorToast(err)
      },
    }
  )
}
