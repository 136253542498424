import { useQuery, UseQueryResult } from 'react-query'

import axios, { AxiosError } from 'axios'
import { ScheduleDto } from '@dentitek-cloud/shared/dtos/schedule/scheduleDto'

import { toDailySchedule } from '@/app/api/mappers/dailySchedule/dailyScheduleMappers'
import { useAppState } from '@/app/contexts/app/AppContext'
import { useFreshToken } from '@/app/contexts/auth/AuthContext'
import { useAppEnv } from '@/app/environment/AppEnvProvider'
import { formatTwoDigitsDateWithHyphen } from '@/app/helpers/date'
import { useErrorToast } from '@/app/hooks/useErrorToast'
import { DailySchedule } from '@/app/models/DailySchedule'

export const useDailySchedule = (date: Date): UseQueryResult<DailySchedule | undefined> => {
  const { state } = useAppState()
  const freshTokenGetter = useFreshToken()
  const showErrorToast = useErrorToast()

  const {
    api: { baseURL },
  } = useAppEnv()

  return useQuery(
    ['dailySchedule', date],
    async (): Promise<DailySchedule | undefined> => {
      if (!state.clinic) {
        return undefined
      }

      const token = await freshTokenGetter()
      const httpClient = axios.create({
        baseURL,
      })

      const response = await httpClient.get<ScheduleDto>(
        `clinics/${state.clinic?.id}/schedule/${formatTwoDigitsDateWithHyphen(date)}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      return toDailySchedule(response.data, date)
    },
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      onError: (err: AxiosError) => {
        showErrorToast(err)
      },
    }
  )
}
