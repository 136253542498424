import type { FC } from 'react'

import type { BoxProps } from '@chakra-ui/react'
import { Box, Center, Spinner } from '@chakra-ui/react'

import { useAppState } from '@/app/contexts/app/AppContext'
import { useDailySchedule } from '@/app/hooks/useDailySchedule'
import { ScheduleColumn } from '@/app/models/ScheduleColumn'
import { AppointmentEvent, ScheduleEvent } from '@/app/models/ScheduleEvent'
import { AppointmentCard } from '@/ui/common/components/appointmentCard/AppointmentCard'
import { NoteCard } from '@/ui/common/components/noteCard/NoteCard'
import { CardRenderer } from '@/ui/common/components/scheduler/components/columnEvents/ColumnEvents'
import { Scheduler } from '@/ui/common/components/scheduler/Scheduler'
import { GenericCard, GenericColumn } from '@/ui/common/components/scheduler/types/Scheduler'

import { useStepColorResolver } from './hooks/useStepColorResolver'

export type SchedulePanelProps = BoxProps

const STEP_HEIGHT_PX = 14

export const SchedulePanel: FC<SchedulePanelProps> = ({ p = 2, ...props }) => {
  const { state } = useAppState()
  const { data: dailySchedule, isError, isLoading } = useDailySchedule(state.selectedDate)
  const stepColorResolver = useStepColorResolver(dailySchedule)

  /* istanbul ignore next */
  const cardRenderer: CardRenderer<ScheduleEvent, GenericColumn> = (room, card, sx) => {
    if ((card as GenericCard<AppointmentEvent>).patient) {
      return (
        <AppointmentCard
          key={card.id}
          appointment={card as GenericCard<AppointmentEvent>}
          borderColor={card.style?.backgroundColor}
          {...sx}
        />
      )
    }
    return <NoteCard key={card.id} note={card} borderColor={card.style?.backgroundColor} {...sx} />
  }

  return (
    <Box layerStyle='contentBox' p={p} overflow='hidden' {...props}>
      {isError || isLoading || !dailySchedule ? (
        <Center minH='100%'>
          <Spinner size='xl' />
        </Center>
      ) : (
        <Scheduler<ScheduleEvent, ScheduleColumn>
          maxW='100%'
          maxH='100%'
          start={dailySchedule.start}
          end={dailySchedule.end}
          step={dailySchedule.scheduleStep}
          stepHeightPx={STEP_HEIGHT_PX}
          columns={dailySchedule.columns}
          events={dailySchedule.appointments}
          cardRenderer={cardRenderer}
          stepStylePropGetter={stepColorResolver}
        />
      )}
    </Box>
  )
}
