import type { FC } from 'react'

import type { GridProps } from '@chakra-ui/react'
import { Box, Grid } from '@chakra-ui/react'

import { useAppState } from '@/app/contexts/app/AppContext'
import { Calendar } from '@/ui/common/components/calendar/Calendar'
import { ScheduleEventDetails } from '@/ui/common/components/scheduleEventDetails/ScheduleEventDetails'

export type SidePanelProps = Omit<GridProps, 'templateRows'>

export const SidePanel: FC<SidePanelProps> = ({ gap = 4, ...props }) => {
  const { state } = useAppState()

  return (
    <Grid
      templateRows='var(--calendar-view-height) minmax(auto, var(--appointment-details-max-view-height))'
      gap={gap}
      {...props}
    >
      <Box layerStyle='contentBox' px={12} py={4}>
        <Calendar />
      </Box>
      <Box layerStyle='contentBox' overflowY='auto'>
        <ScheduleEventDetails scheduleEvent={state.selectedScheduleEvent} />
      </Box>
    </Grid>
  )
}
