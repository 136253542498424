export enum IconSet {
  None = 'None',
  MedicalAlert = 'MedicalAlert',
  Unknown = 'Unknown',
  Email = 'Email',
  Confirmed = 'Confirmed',
  Pending = 'Pending',
  Declined = 'Declined',
  Logout = 'Logout',
  AlternateConfirmed = 'AlternateConfirmed',
  Paid = 'Paid',
  Phone = 'Phone',
}
