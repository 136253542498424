import type { FC } from 'react'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { StackProps } from '@chakra-ui/react'
import { HStack, StackDivider, Text } from '@chakra-ui/react'

import { useAppState } from '@/app/contexts/app/AppContext'
import { useSignOut, useUser } from '@/app/contexts/auth/AuthContext'
import { IconSvg } from '@/ui/common/components/iconSvg/IconSvg'
import { IconSet } from '@/ui/common/components/iconSvg/types/IconSet.enum'

export type ConnectedUserViewProps = StackProps

export const ConnectedUserView: FC<ConnectedUserViewProps> = ({ spacing = 4, ...props }) => {
  const user = useUser()
  const signOut = useSignOut()
  const { state } = useAppState()
  const [signingOut, setSigningOut] = useState(false)

  const { t } = useTranslation()

  const onClickLogout = useCallback(() => {
    const doLogout = async () => {
      await signOut()
    }
    setSigningOut(true)
    state.clinicStorage?.set(undefined)
    // eslint-disable-next-line no-void
    void doLogout()
  }, [signOut, state.clinicStorage])

  return (
    <HStack spacing={spacing} {...props}>
      {user?.email && (
        <HStack spacing={16}>
          <HStack spacing={4} divider={<StackDivider borderColor='gray.400' />} my={3}>
            <Text fontSize='xs' fontWeight={600} my={1}>
              {user?.name}
            </Text>
            <Text fontSize='xs' fontWeight={600} my={1}>
              {state.clinic?.name}
            </Text>
          </HStack>

          <HStack
            spacing={2}
            my={3}
            cursor='pointer'
            onClick={() => onClickLogout()}
            _hover={{ color: 'teal.500' }}
            _disabled={{ pointerEvents: 'none', opacity: 0.25 }}
            aria-disabled={signingOut}
            data-testid='menu-logout'
          >
            <Text fontSize='xs' my={1}>
              {t('logout')}
            </Text>
            <IconSvg as={IconSet.Logout} />
          </HStack>
        </HStack>
      )}
    </HStack>
  )
}
