import type { FC } from 'react'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Box, Button, Center, Flex, Heading, Radio, RadioGroup, Select, Spinner, VStack } from '@chakra-ui/react'

import { useAppState } from '@/app/contexts/app/AppContext'
import { useClinics } from '@/app/hooks/useClinics'
import { Clinic } from '@/app/models/Clinic'
import { DentitekLogo } from '@/ui/appLayout/components/dentitekLogo/DentitekLogo'

export const PickClinic: FC = () => {
  const { t } = useTranslation()
  const { state } = useAppState()
  const location = useLocation()
  const navigate = useNavigate()

  const [selected, setSelected] = useState<Clinic['id'] | undefined>(undefined)

  const { data, isLoading, isError } = useClinics()

  const navigateToMain = useCallback(() => {
    navigate('/', { replace: true, state: { from: location } })
  }, [location, navigate])

  const pickClinic = useCallback(
    (clinicId?: Clinic['id']) => {
      state.clinicStorage?.set(clinicId)
      setSelected(clinicId)
    },
    [state.clinicStorage]
  )

  const clinicPicker =
    data && data.length <= 10 ? (
      <RadioGroup onChange={pickClinic} value={selected}>
        <VStack spacing={2} align='flex-start'>
          {data?.map((clinic) => (
            <Radio key={clinic.id} value={clinic.id} data-testid={`radio-${clinic.id}`}>
              {clinic.name}
            </Radio>
          ))}
        </VStack>
      </RadioGroup>
    ) : (
      <Select
        onChange={(evt) => pickClinic(evt.target.value)}
        value={selected || ''}
        minW={{ sm: '0', md: '40ch' }}
        autoFocus
        data-testid='SelectClinic-dropdown'
      >
        <option value='' disabled>
          {t('select')}
        </option>
        {data?.map((clinic) => (
          <option key={clinic.id} value={clinic.id} data-testid={clinic.id}>
            {clinic.name}
          </option>
        ))}
      </Select>
    )

  if (isLoading || isError) {
    return (
      <Center minH='100vh'>
        <Spinner size='xl' />
      </Center>
    )
  }

  return (
    <Center minH='100vh'>
      <VStack spacing={12} maxHeight='100vh' overflowY='auto'>
        <DentitekLogo scale={1.375} translateY='25%' />

        <Box layerStyle='contentBox' minH='fit-content' px='8vw' py={8}>
          <VStack spacing={8} align='flex-start'>
            <Heading as='h1' size='md'>
              {t('pickClinic')}
            </Heading>
            {clinicPicker}

            <Flex justify='center' w='100%'>
              <Button disabled={!selected} onClick={() => navigateToMain()} data-testid='select-clinic-btn'>
                {t('select')}
              </Button>
            </Flex>
          </VStack>
        </Box>
      </VStack>
    </Center>
  )
}
