import type { FC } from 'react'

import type { FlexProps } from '@chakra-ui/react'
import { Flex } from '@chakra-ui/react'

import { Header } from '@/ui/appLayout/components/header/Header'

export type AppLayoutProps = FlexProps

export const AppLayout: FC<AppLayoutProps> = ({ children, ...props }) => {
  return (
    <Flex direction='column' h='100vh' px='var(--page-padding)' {...props}>
      <Header />
      {children}
    </Flex>
  )
}
