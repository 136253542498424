import type { FC } from 'react'

import { Text } from '@chakra-ui/react'

import { useLabelColor } from '../hooks/useLabelColor'

export type LabelValueMultilineRowProps = {
  label: string
  value: string
}

export const LabelValueMultilineRow: FC<LabelValueMultilineRowProps> = ({ label, value }) => {
  const labelColor = useLabelColor()

  return (
    <>
      <Text fontSize='xs' mb={1} fontWeight={700} color={labelColor}>
        {label}
      </Text>
      <Text fontSize='xs' fontWeight={400}>
        {value}
      </Text>
    </>
  )
}
