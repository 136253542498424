import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import axios from 'axios'
import _isString from 'lodash/isString'

import { useNotification } from '@/app/hooks/useNotification'

export type AxiosErrorResponseData = {
  message: string
}

function getErrorDescription(error: Error): string {
  if (!axios.isAxiosError(error)) {
    return error.message
  }

  if (_isString(error.response?.data)) {
    return error.response?.data as string
  }

  const axiosErrorData = error.response?.data as AxiosErrorResponseData

  return axiosErrorData?.message
}

export type ShowErrorCallback = (error: Error) => void

export const useErrorToast = (): ShowErrorCallback => {
  const { t } = useTranslation()
  const notification = useNotification()

  return useCallback(
    (error: Error) => {
      notification({
        title: t('errorTitle'),
        description: getErrorDescription(error),
        status: 'error',
      })
    },
    [notification, t]
  )
}
