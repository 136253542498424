import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid, Text } from '@chakra-ui/react'

export const EmptyScheduleEventDetails: FC = () => {
  const { t } = useTranslation('appointmentDetails')
  return (
    <Grid placeItems='center' h='100%'>
      <Text fontSize='xs' color='gray.500'>
        {t('noAppointmentSelected')}
      </Text>
    </Grid>
  )
}
