export const formatPhoneNumber = (phoneNumber: string | undefined): string | undefined => {
  const numberWithoutSpaces = `${phoneNumber}`.replace(/\D/g, '')
  const match = numberWithoutSpaces.match(/^(1)?(\d{3})(\d{3})(\d{4})(\d+)?$/)
  if (match) {
    const countryCode = match[1] ? `${match[1]} ` : ''
    const areaCode = match[2]
    const exchangeCode = match[3]
    const lineNumber = match[4]
    const extension = match[5] ? ` ext. ${match[5]}` : ''

    return `${countryCode}${areaCode} ${exchangeCode}-${lineNumber}${extension}`
  }
  return undefined
}
