import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'

import { DailySchedule } from '@/app/models/DailySchedule'
import { ScheduleColumn } from '@/app/models/ScheduleColumn'
import { StepStylePropGetter } from '@/ui/common/components/scheduler/types/Scheduler'

import { columnsToTimeSlotsReducer } from './columnsToTimeSlotsReducer'

dayjs.extend(isBetween)

export const useStepColorResolver = (
  dailySchedule?: DailySchedule
): StepStylePropGetter<ScheduleColumn> | undefined => {
  if (!dailySchedule) {
    return undefined
  }
  const timeSlotsByColumnId = dailySchedule.columns.reduce(columnsToTimeSlotsReducer, new Map())

  return (column, date) => {
    const timeSlots = timeSlotsByColumnId.get(column.id)
    if (timeSlots) {
      for (let i = 0; i < timeSlots.length; i += 1) {
        const timeSlot = timeSlots[i]
        if (dayjs(date).isBetween(timeSlot.start, timeSlot.end, 'minutes', '[)')) {
          return {
            bg: timeSlot.color,
          }
        }
      }
    }

    return {
      bg: 'transparent',
    }
  }
}
