import type { Colors } from '@chakra-ui/react'

export const BRAND_COLORS = {
  accent: '#E50695',
  black: '#353534',
}

const definedColors = {
  gray: {
    50: '#f7fafc',
    100: '#edf2f7',
    200: '#e2e8f0',
    300: '#cbd5e0',
    400: '#a0aec0',
    500: '#718096',
    600: '#4a5568',
    700: '#2d3748',
    800: '#1c222f',
    900: '#171923',
    950: '#11131a',
  },
  pink: {
    50: '#FEE9F8',
    100: '#FECEEE',
    200: '#FC97DA',
    300: '#FB60C6',
    400: '#F92AB1',
    500: BRAND_COLORS.accent,
    600: '#B30573',
    700: '#820352',
    800: '#500232',
    900: '#1E0113',
  },
  teal: {
    50: '#e6fffa',
    100: '#b2f5ea',
    200: '#81e6d9',
    300: '#4fd1c5',
    400: '#38b2ac',
    500: '#319795',
    600: '#2c7a7b',
    700: '#285e61',
    800: '#234e52',
    900: '#1d4044',
  },
  green: {
    500: '#38a169',
  },
  orange: {
    500: '#dd6b20',
  },
  red: {
    500: '#e53e3e',
  },
  yellow: {
    300: '#f6e05e',
  },
  white: '#ffffff',
  black: '#000000',
}

export const ColorsTheme: Colors = {
  error: {
    light: definedColors.red[500],
    dark: definedColors.red[500],
  },

  text: {
    light: definedColors.gray[100],
    dark: definedColors.gray[800],
  },

  pink: {
    light: definedColors.pink[400],
    dark: definedColors.pink[800],
    ...definedColors.pink,
  },

  teal: {
    light: definedColors.teal[400],
    dark: definedColors.teal[800],
    ...definedColors.teal,
  },

  gray: definedColors.gray,
  green: definedColors.green,
  orange: definedColors.orange,
  red: definedColors.red,
  yellow: definedColors.yellow,
  white: definedColors.white,
  black: definedColors.black,

  bg: {
    light: definedColors.white,
    dark: definedColors.gray[950],
  },
}
