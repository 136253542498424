import type { FC } from 'react'

import { Grid } from '@chakra-ui/react'

import { AppLayout } from '@/ui/appLayout/AppLayout'
import { SchedulePanel } from '@/ui/mainView/components/schedulePanel/SchedulePanel'
import { SidePanel } from '@/ui/mainView/components/sidePanel/SidePanel'

export const MainView: FC = () => (
  <AppLayout>
    <Grid h='calc(100vh - var(--header-height))' templateColumns='var(--side-panel-width) auto' gap={4}>
      <SidePanel />
      <SchedulePanel h='var(--main-view-height)' />
    </Grid>
  </AppLayout>
)
