import type { FC } from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { Box, Center, Heading, Text } from '@chakra-ui/react'

import { AppLayout } from '@/ui/appLayout/AppLayout'

type LocationState = {
  from: string
  type?: string
  clinicIds?: string
}

export const Error404: FC = () => {
  const { t } = useTranslation()
  const location = useLocation()

  const message = useMemo(() => {
    /* istanbul ignore next */
    if (location.state == null) {
      return t('error404')
    }
    const { type, clinicIds } = location.state as LocationState

    switch (type) {
      case 'Empty cognito clinic ids':
        return t('errors.emptyClinicIds')
      case 'Invalid cognito clinic ids': {
        if (clinicIds) {
          const ids = clinicIds.split(/\s*,\s*/)
          return t('errors.invalidClinicIds', { count: ids.length, ids: clinicIds })
        }
        return t('errors.invalidClinicIdsUnknown')
      }
      default:
        return t('error404')
    }
  }, [location.state, t])

  return (
    <AppLayout>
      <Center minH='100vh'>
        <Box>
          <Heading as='h1'>{t('errorTitle')}</Heading>
          <Text>{message}</Text>
        </Box>
      </Center>
    </AppLayout>
  )
}
