import { useMemo } from 'react'

import { useColorModeValue } from '@chakra-ui/react'

import { VisibilityLevel } from '../types/AppointmentDetails'

export const useValueColor = (value: string, visibilityLevel: VisibilityLevel): string => {
  const emptyColor = useColorModeValue('blackAlpha.400', 'whiteAlpha.400')
  const textColor = useColorModeValue('text.dark', 'text.light')
  const redColor = useColorModeValue('red.500', 'red.600')

  return useMemo<string>(() => {
    if (!value) {
      return emptyColor
    }
    return visibilityLevel === VisibilityLevel.Alert ? redColor : textColor
  }, [emptyColor, redColor, textColor, value, visibilityLevel])
}
