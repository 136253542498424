import type { FC } from 'react'
import { useMemo } from 'react'

import { Text } from '@chakra-ui/react'

import { getDateAtStartOfNextHour, getHoursMinsFormattedTime } from '@/app/helpers/time'
import { useSteps } from '@/ui/common/components/scheduler/hooks/useSteps'
import { SchedulerStep } from '@/ui/common/components/scheduler/types/Scheduler'

export type TimeStepsProps = {
  min: Date
  max: Date
  step: SchedulerStep
  stepHeight: number
  bg?: string
}

export const TimeSteps: FC<TimeStepsProps> = ({ min, max, step, stepHeight, bg }) => {
  const steps = useSteps(min, max, step)
  const endHour = useMemo(() => getDateAtStartOfNextHour(max), [max])

  return (
    <>
      {steps.map(({ start, isHour }) => (
        <Text
          key={start.toISOString()}
          className={isHour ? 'scheduler-step-hour' : 'scheduler-step-minute'}
          height={`${stepHeight}px`}
        >
          {getHoursMinsFormattedTime(start)}
        </Text>
      ))}
      <Text className='scheduler-close-hour' height={`${stepHeight}px`} bg={bg}>
        {getHoursMinsFormattedTime(endHour)}
      </Text>
    </>
  )
}
