import type { Dispatch, FC } from 'react'
import { createContext, useContext, useMemo, useReducer } from 'react'

import _noop from 'lodash/noop'

import { AppContextLogic } from '@/app/contexts/app/AppContextLogic'
import { getDateNow } from '@/app/helpers/date'
import { ClinicLocalStorage } from '@/app/services/ClinicLocalStorage'

import { AppActions, AppState } from './types/AppContext'

export type AppContextValue = {
  state: AppState
  dispatch: Dispatch<AppActions>
}

const AppContext = createContext<AppContextValue>({
  state: { selectedDate: getDateNow() },
  dispatch: _noop,
})

export type AppContextProviderProps = {
  clinicStorage?: ClinicLocalStorage
}

export const AppContextProvider: FC<AppContextProviderProps> = ({ clinicStorage, children }) => {
  const [state, dispatch] = useReducer(AppContextLogic.reducer, {
    selectedDate: getDateNow(),
    clinicStorage,
  })

  const memoizedValue = useMemo<AppContextValue>(() => ({ state, dispatch }), [state])

  return <AppContext.Provider value={memoizedValue}>{children}</AppContext.Provider>
}

export const useAppState = (): AppContextValue => useContext(AppContext)
