import { useMemo } from 'react'

import { getDateAtStartOfHour, getDateAtStartOfNextHour } from '@/app/helpers/time'
import type { SchedulerStep } from '@/ui/common/components/scheduler/types/Scheduler'

export type Step = {
  start: Date
  isHour: boolean
}

export const useSteps = (min: Date, max: Date, step: SchedulerStep): Step[] => {
  return useMemo(() => {
    const floorMin = getDateAtStartOfHour(min)
    const ceilMax = getDateAtStartOfNextHour(max)
    const maxTicks = 60 / step
    const out: Step[] = []
    const hourStepper = new Date(floorMin)
    while (hourStepper < ceilMax) {
      out.push({ start: new Date(hourStepper), isHour: true })
      const minuteStepper = new Date(hourStepper)
      for (let tick = 1; tick < maxTicks; tick += 1) {
        minuteStepper.setMinutes(minuteStepper.getMinutes() + step)
        out.push({ start: new Date(minuteStepper), isHour: false })
      }
      hourStepper.setHours(hourStepper.getHours() + 1)
    }
    return out
  }, [step, min, max])
}
