import { DASH } from '@/app/helpers/types/dash'
import { SPACE } from '@/app/helpers/types/space'
import { AppointmentReminder } from '@/app/models/ScheduleEvent'

export const appointmentDetails = {
  [AppointmentReminder.YES]: 'yes',
  [AppointmentReminder.NO]: 'no',
  address: 'Address',
  age_zero: ` ${DASH.EN} {{count}}${SPACE.NBSP}yr.${SPACE.NBSP}old`,
  age_one: ` ${DASH.EN} {{count}}${SPACE.NBSP}yr.${SPACE.NBSP}old`,
  age_other: ` ${DASH.EN} {{count}}${SPACE.NBSP}yr.${SPACE.NBSP}old`,
  allergies: 'Med. A.',
  birthdate: 'Birthdate',
  contactInfo: {
    cellphone: 'Cellphone',
    email: 'Email',
    officePhone: 'Office phone',
    residentialPhone: 'Residential Phone',
  },
  costAfterInsurance: 'Cost after insurance',
  description: 'Description',
  headingContent: '{{patient.firstName}} {{patient.lastName}}$t(age, {"count": {{patient.age}} })',
  headingContent_noAge: `{{patient.firstName}} {{patient.lastName}}`,
  operatorName: 'Operator',
  RAMQ: 'RAMQ',
  reminder: 'Reminder',
  referrerName: 'Referrer',
  responsibleAdult: 'Responsible adult',
  noAppointmentSelected: 'No appointment selected.',
  comment: 'Comment:',
}
