import { AppActions, AppActionType, AppState } from '@/app/contexts/app/types/AppContext'
import { ScheduleEvent } from '@/app/models/ScheduleEvent'

const onScheduleEventSelection = (
  previousAppointment: ScheduleEvent | undefined,
  nextAppointment: ScheduleEvent | undefined
): ScheduleEvent | undefined => {
  if (!previousAppointment) {
    return nextAppointment
  }
  if (nextAppointment?.id === previousAppointment.id) {
    return undefined
  }
  return nextAppointment
}

export const AppContextLogic = {
  reducer: (state: AppState, action: AppActions): AppState => {
    switch (action.type) {
      case AppActionType.SetDate:
        return {
          ...state,
          selectedDate: action.date,
          selectedScheduleEvent: undefined,
        }

      case AppActionType.SetClinic: {
        if (state.clinicStorage) {
          state.clinicStorage.set(action.clinic?.id)
        }
        return {
          ...state,
          clinic: action.clinic,
        }
      }

      case AppActionType.SetScheduleEvent:
        return {
          ...state,
          selectedScheduleEvent: onScheduleEventSelection(state.selectedScheduleEvent, action.scheduleEvent),
        }
      default:
        return state
    }
  },
}
