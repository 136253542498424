import { Patient } from '@/app/models/Patient'
import { ScheduleColumn } from '@/app/models/ScheduleColumn'
import { GenericCard, GenericEvent } from '@/ui/common/components/scheduler/types/Scheduler'

export enum AppointmentReminder {
  YES = 'yes',
  NO = 'no',
}

export interface AppointmentEvent extends ScheduleEvent {
  patient: Patient
  treatmentType: string
  confirmationId?: number
  reminder: AppointmentReminder
  costAfterInsurance: number
}

export interface ScheduleEvent extends GenericEvent {
  columnId: ScheduleColumn['id']
  comment: string
  status?: string
  style?: {
    bandColor: string
    backgroundColor: string
  }
}

export type Card = GenericCard<ScheduleEvent>
