// global styles: https://chakra-ui.com/docs/features/global-styles

import { SystemStyleObject } from '@chakra-ui/react'
import type { Dict } from '@chakra-ui/utils'

export const styles = {
  global: (props: Dict): SystemStyleObject => ({
    '#root': {
      '--scheduler-step-height': '14px',
      '--scheduler-header-height': '24px',
      '--scheduler-column-width': '264px',
      '--header-height': '76px',
      '--main-view-height': 'calc(100vh - var(--chakra-space-3) - var(--header-height))',
      '--side-panel-width': '30ch',
      '--page-padding': '1.75vw',
      '--calendar-view-height': '284px',
      '--appointment-details-max-view-height': 'calc(var(--main-view-height) - var(--calendar-view-height) - 1rem)',
      background: props.colorMode === 'light' ? 'gray.100' : 'gray.800',
      '.sb-show-main &': {
        display: 'contents',
      },
      '*::-webkit-scrollbar': {
        width: '10px',
        height: '10px',
        backgroundColor: 'blackAlpha.200',
        borderRadius: '5px',
        margin: '3px',
        padding: '3px',
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: 'gray.300',
        borderRadius: '5px',
      },
    },
    body: {
      overscrollBehavior: 'none',
    },
    '.is-react-icon': {
      display: 'inline-block',
      verticalAlign: 'middle',
      transform: 'translateY(-1px)',
    },
  }),
}
