import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { AppointmentDto, ColumnDto, ScheduleDto } from '@dentitek-cloud/shared/dtos/schedule/scheduleDto'

import { timeStringToDate } from '@/app/helpers/date'
import { AppointmentsMap, DailySchedule } from '@/app/models/DailySchedule'
import { ScheduleColumn, TimeSlot } from '@/app/models/ScheduleColumn'
import { AppointmentEvent, AppointmentReminder, ScheduleEvent } from '@/app/models/ScheduleEvent'

dayjs.extend(customParseFormat)

const toTimeSlot = (
  dto: {
    start: string
    end: string
    color: string
  },
  date: Date
): TimeSlot => ({
  start: timeStringToDate(dto.start, date),
  end: timeStringToDate(dto.end, date),
  color: dto.color,
})

const toColumn = (dto: ColumnDto, date: Date): ScheduleColumn => ({
  ...dto,
  timeSlots: dto.timeSlots.map((timeSlot) => toTimeSlot(timeSlot, date)),
  id: String(dto.id),
  label: dto.name,
})

function isNote(dto: AppointmentDto): boolean {
  return !dto.patient
}

function toScheduleEvent(dto: AppointmentDto, columnId: number): ScheduleEvent {
  if (!isNote(dto)) {
    return {
      ...dto,
      id: String(dto.id),
      columnId: String(columnId),
      start: new Date(dto.start),
      end: new Date(dto.end),
      treatmentType: dto.treatmentType,
      comment: dto.comment,
      patient: dto.patient,
      reminder: AppointmentReminder.NO,
      costAfterInsurance: 0,
      status: dto.status ?? '',
    } as AppointmentEvent
  }
  return {
    id: String(dto.id),
    columnId: String(columnId),
    start: new Date(dto.start),
    end: new Date(dto.end),
    comment: dto.comment,
    style: dto.style,
  } as ScheduleEvent
}

const toAppointments = (columns: ColumnDto[]): Map<string, ScheduleEvent[]> => {
  const appointments: AppointmentsMap = new Map()
  columns.forEach((column) => {
    appointments.set(
      String(column.id),
      column.appointments.map((appointment) => toScheduleEvent(appointment, column.id))
    )
  })

  return appointments
}

export const toDailySchedule = ({ scheduleStep, start, end, columns }: ScheduleDto, date: Date): DailySchedule => ({
  scheduleStep,
  start: timeStringToDate(start, date),
  end: timeStringToDate(end, date),
  columns: columns.map((column) => toColumn(column, date)),
  appointments: toAppointments(columns),
})
