import type { JSXElementConstructor, ReactElement } from 'react'

import './ColumnSteps.styles.css'

import { useSteps } from '@/ui/common/components/scheduler/hooks/useSteps'

import { GenericColumn, SchedulerStep, StepStylePropGetter } from '../../types/Scheduler'
import { ColumnStep } from '../columnStep/ColumnStep'

export type ColumnStepsProps<C extends GenericColumn> = {
  min: Date
  max: Date
  step: SchedulerStep
  stepHeight: number
  column: C
  stepStylePropGetter?: StepStylePropGetter<C>
}

export const ColumnSteps = <C extends GenericColumn>({
  min,
  max,
  step,
  column,
  stepHeight,
  stepStylePropGetter,
}: ColumnStepsProps<C>): ReactElement<ColumnStepsProps<C>, JSXElementConstructor<HTMLDivElement>> => {
  const steps = useSteps(min, max, step)

  return (
    <>
      {steps.map(({ start, isHour }) => (
        <ColumnStep<C>
          key={`${column.id}-${start.getTime()}`}
          stepHeight={stepHeight}
          column={column}
          start={start}
          isHour={isHour}
          stepStylePropGetter={stepStylePropGetter}
        />
      ))}
    </>
  )
}
