import { DASH } from '@/app/helpers/types/dash'
import { SPACE } from '@/app/helpers/types/space'
import { AppointmentReminder } from '@/app/models/ScheduleEvent'

export const appointmentDetails = {
  [AppointmentReminder.YES]: 'oui',
  [AppointmentReminder.NO]: 'non',
  address: 'Adresse',
  age_zero: ` ${DASH.EN} {{count}}${SPACE.NBSP}an`,
  age_one: ` ${DASH.EN} {{count}}${SPACE.NBSP}an`,
  age_other: ` ${DASH.EN} {{count}}${SPACE.NBSP}ans`,
  medicalAlert: 'A. Méd',
  birthdate: 'Naissance',
  contactInfo: {
    cellphone: 'Cellulaire',
    email: 'Courriel',
    officePhone: 'Tél. bureau',
    residentialPhone: 'Tél. résidentiel',
  },
  costAfterInsurance: 'Montant réel',
  description: 'Description',
  headingContent: '{{patient.firstName}} {{patient.lastName}}$t(age, {"count": {{patient.age}} })',
  headingContent_noAge: `{{patient.firstName}} {{patient.lastName}}`,
  operatorName: 'Opérateur',
  RAMQ: 'RAMQ',
  reminder: 'Rappel',
  referrerName: 'Référent',
  responsibleAdult: 'Responsable',
  noAppointmentSelected: 'Aucun rendez-vous sélectionné.',
  comment: `Commentaire${SPACE.NNBSP}:`,
}
