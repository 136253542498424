import { reduce } from 'lodash'

import { EmptyCognitoClinicIdsError } from './emptyCognitoClinicIdsError'
import { InvalidCognitoClinicIdsError } from './invalidCognitoClinicIdsError'

export const getClinicIdSpecialistIdMap = (clinicSpecialistIdsString?: string): Record<string, string> => {
  if (!clinicSpecialistIdsString) {
    throw new EmptyCognitoClinicIdsError()
  }

  const clinicSpecialistString = clinicSpecialistIdsString
    .split(/\s*,\s*/)
    .map((clinicIdSpecialistId) => clinicIdSpecialistId)

  const clinicIdSpecialistIdMap = reduce(
    clinicSpecialistString,
    (result: Record<string, string>, value) => {
      const [clinicId, specialistId] = value.split('-')
      if (!clinicId || !specialistId) {
        throw new InvalidCognitoClinicIdsError(clinicSpecialistIdsString)
      }
      return { ...result, [clinicId]: specialistId }
    },
    {}
  )

  if (Object.keys(clinicIdSpecialistIdMap).length === 0) {
    throw new EmptyCognitoClinicIdsError()
  }

  return clinicIdSpecialistIdMap
}
