import { SPACE } from '@/app/helpers/types/space'
import { Address } from '@/app/models/Patient'

const formatPostalCode = (code?: string): string | undefined => {
  if (code) {
    const postalCodeWithoutSpaces = code.replace(/\s/g, '')
    const match = postalCodeWithoutSpaces.match(/^(\w\d\w)(\d\w\d)$/)
    if (match) {
      return `${match[1]}${SPACE.NBSP}${match[2]}`
    }
  }
  return undefined
}

export const formatAddress = (address: Address): string =>
  [address.streetAddress, address.city, address.province, formatPostalCode(address.postalCode)]
    .filter((item) => item)
    .join(', ')
