import type { FC } from 'react'

import { HStack } from '@chakra-ui/react'

import { ConnectedUserView } from '@/ui/appLayout/components/connectedUserView/ConnectedUserView'
import { DentitekLogo } from '@/ui/appLayout/components/dentitekLogo/DentitekLogo'

export const Header: FC = () => {
  return (
    <HStack justify='space-between' h='var(--header-height)' align='center' zIndex={9}>
      <DentitekLogo />
      <ConnectedUserView />
    </HStack>
  )
}
