import { SystemStyleObject, ThemeComponentProps } from '@chakra-ui/react'

import { fontFamily } from '@/ui/theme/font-family'

export const TabsTheme = {
  baseStyle: {
    borderRadius: 'md',
    fontFamily,
    fontWeight: 600,
  },
  sizes: {
    xs: {
      fontSize: '0.75rem',
    },
    sm: {
      fontSize: '0.875rem',
    },
    md: {
      fontSize: '1rem',
    },
    lg: {
      fontSize: '1.125rem',
    },
  },
  variants: {
    line: ({ colorMode }: ThemeComponentProps): SystemStyleObject => {
      const selectedColor = colorMode === 'light' ? `gray.800` : `white`

      return {
        tablist: {
          borderBottomWidth: 1,
        },
        tab: {
          color: 'gray.500',
          py: 4,
          fontWeight: 400,
          borderBottomWidth: 1,
          marginBottom: '-1px',
          _selected: {
            color: selectedColor,
            fontWeight: 500,
          },
        },
      }
    },
  },
}
