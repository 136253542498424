import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Divider, Stack } from '@chakra-ui/react'

import { formatAddress } from '@/app/helpers/address'
import { formatPhoneNumber } from '@/app/helpers/phone'
import { formatRAMQ } from '@/app/helpers/ramq'
import { AppointmentEvent } from '@/app/models/ScheduleEvent'
import { ContentHeader } from '@/ui/common/components/scheduleEventDetails/components/ContentHeader'
import { LabelValueMultilineRow } from '@/ui/common/components/scheduleEventDetails/components/LabelValueMultiLineRow'
import { LabelValueRow } from '@/ui/common/components/scheduleEventDetails/components/LabelValueRow'
import { VisibilityLevel } from '@/ui/common/components/scheduleEventDetails/types/AppointmentDetails'

type FilledAppointmentDetailsProps = {
  appointment: AppointmentEvent
}

export const FilledAppointmentDetails: FC<FilledAppointmentDetailsProps> = ({
  appointment: { confirmationId, patient, comment },
}) => {
  const { t } = useTranslation('appointmentDetails')
  const formattedAddress = useMemo(() => formatAddress(patient.address), [patient.address])
  const formattedResidentialPhone = useMemo(
    () => formatPhoneNumber(patient.contactInfo.residentialPhone),
    [patient.contactInfo.residentialPhone]
  )
  const formattedOfficePhone = useMemo(
    () => formatPhoneNumber(patient.contactInfo.officePhone),
    [patient.contactInfo.officePhone]
  )
  const formattedCellphone = useMemo(
    () => formatPhoneNumber(patient.contactInfo.cellphone),
    [patient.contactInfo.cellphone]
  )
  const formattedRAMQ = useMemo(() => formatRAMQ(patient.RAMQ), [patient.RAMQ])

  return (
    <Box lineHeight={1.1}>
      <Box mb={5}>
        <ContentHeader patient={patient} confirmationId={confirmationId} />
      </Box>
      {formattedAddress ? (
        <LabelValueMultilineRow label={t('address')} value={formattedAddress} />
      ) : (
        <LabelValueRow label={t('address')} />
      )}
      <Divider my={4} />
      <Stack spacing={3}>
        <LabelValueRow label={t('contactInfo.residentialPhone')} value={formattedResidentialPhone} />
        <LabelValueRow label={t('contactInfo.officePhone')} value={formattedOfficePhone} />
        <LabelValueRow label={t('contactInfo.cellphone')} value={formattedCellphone} />
        <LabelValueRow label={t('contactInfo.email')} value={patient.contactInfo.email} />
      </Stack>
      <Divider my={4} />
      <Stack spacing={3}>
        <LabelValueRow label={t('birthdate')} value={patient.birthdate} />
        <LabelValueRow label={t('medicalAlert')} value={patient.medicalAlert} visibilityLevel={VisibilityLevel.Alert} />
        <LabelValueRow label={t('referrerName')} value={patient.referrerName} />
      </Stack>
      <Divider my={4} />
      <Stack spacing={3}>
        <LabelValueRow label={t('description')} value={comment} />
        <LabelValueRow label={t('responsibleAdult')} value={patient.responsibleAdult} />
        <LabelValueRow label={t('RAMQ')} value={formattedRAMQ} />
      </Stack>
    </Box>
  )
}
