import { useCallback } from 'react'

import type { GenericColumn, GenericEvent } from '@/ui/common/components/scheduler/types/Scheduler'

export function useGetEventsCallback<E extends GenericEvent, C extends GenericColumn>(
  events: Map<C['id'], Array<E>>
): (columnId: C['id']) => E[] {
  return useCallback(
    (columnId) => {
      const list = events.get(columnId)
      if (list) {
        return Array.from(list.values()).sort((a, b) => {
          // Sort with longest appointment first:
          const lengthA = a.end.getTime() - a.start.getTime()
          const lengthB = b.end.getTime() - b.start.getTime()
          if (lengthA === lengthB) {
            return 0
          }
          return lengthA > lengthB ? -1 : 1
        })
      }
      return []
    },
    [events]
  )
}
