import type { FC } from 'react'
import { useCallback, useMemo, useRef } from 'react'
import type { CalendarProps as ReactCalendarProps } from 'react-calendar'
import ReactCalendar from 'react-calendar'
import { useTranslation } from 'react-i18next'

import dayjs from 'dayjs'
import { Box, Text, useColorModeValue } from '@chakra-ui/react'

import './Calendar.styles.css'

import { useAppState } from '@/app/contexts/app/AppContext'
import { AppActionType } from '@/app/contexts/app/types/AppContext'
import { hashDate } from '@/app/helpers/date'

import { useToday } from './hooks/useToday'
import { ReactCalendarComponent } from './types/ReactCalendar'

export type CalendarProps = Omit<
  ReactCalendarProps,
  | 'className'
  | 'calendarType'
  | 'formatShortWeekday'
  | 'activeStartDate'
  | 'onActiveStartDateChange'
  | 'tileClassName'
  | 'value'
> & {
  specialDates?: Map<string, string>
}

export const SPECIAL_DATES = {
  past: '-is-past',
  orangeStroke: '-orange-stroke',
  purpleStroke: '-purple-stroke',
}

export const Calendar: FC<CalendarProps> = ({ specialDates, ...props }) => {
  const { t, i18n } = useTranslation('calendar')
  const mainClassName = useColorModeValue('-light', '-dark')

  const refCal = useRef<ReactCalendarComponent>(null)
  const { state, dispatch } = useAppState()
  const [today, readableToday] = useToday(i18n.language)
  const weekLabelFormatter = useMemo(() => Intl.DateTimeFormat(i18n.language, { weekday: 'narrow' }), [i18n.language])

  const setTodayCallback = useCallback(() => {
    const firstDayOfMonth = dayjs(today).startOf('month')
    refCal.current?.setStateAndCallCallbacks(
      {
        action: 'onChange',
        view: 'month',
        activeStartDate: firstDayOfMonth.toDate(),
        value: today,
      },
      undefined,
      () => dispatch({ type: AppActionType.SetDate, date: today })
    )
  }, [dispatch, today])

  return (
    <Box>
      <ReactCalendar
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref={refCal}
        className={'month-calendar '.concat(mainClassName)}
        calendarType='US'
        locale={i18n.language}
        formatShortWeekday={(_, date: Date) => weekLabelFormatter.format(date)}
        value={state.selectedDate}
        onChange={(date: Date) => {
          dispatch({ type: AppActionType.SetDate, date })
        }}
        tileClassName={(args) => {
          const out = ['react-calendar__tile']
          if (args.date < today) {
            out.push(SPECIAL_DATES.past)
          }
          const date = hashDate(args.date)
          if (specialDates?.has(date)) {
            const className = specialDates?.get(date)
            if (className) {
              out.push(className)
            }
          }
          return out.join(' ')
        }}
        {...props}
      />
      <Text
        fontSize='xs'
        align='center'
        fontWeight={600}
        cursor='pointer'
        mt={2}
        onClick={setTodayCallback}
        data-testid='action-today'
      >
        {t('today', { date: readableToday })}
      </Text>
    </Box>
  )
}
