import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Heading } from '@chakra-ui/react'

import { Patient } from '@/app/models/Patient'
import { AppointmentStatus } from '@/ui/common/components/appointmentCard/components/appointmentStatus/AppointmentStatus'
import { IconSvg } from '@/ui/common/components/iconSvg/IconSvg'
import { IconSet } from '@/ui/common/components/iconSvg/types/IconSet.enum'
import { testIDs } from '@/ui/common/components/scheduleEventDetails/components/tests/testIDs'

type ContentHeaderProps = {
  patient: Patient
  confirmationId?: number
}

export const ContentHeader: FC<ContentHeaderProps> = ({ patient, confirmationId }: ContentHeaderProps) => {
  const { t } = useTranslation('appointmentDetails')
  const headingContent = useMemo(
    () =>
      patient.age || patient?.age === 0
        ? t('headingContent', { count: patient.age, patient })
        : t('headingContent', { context: 'noAge', patient }),
    [patient, t]
  )

  return (
    <Flex align='center'>
      <Flex w={6} h={6} align='center'>
        {confirmationId && <AppointmentStatus confirmationId={confirmationId} />}
      </Flex>
      <Heading as='h5' mx={2} size='sm' flexGrow='2' textAlign='center'>
        {headingContent}
      </Heading>
      {patient.medicalAlert !== '' && <IconSvg as={IconSet.MedicalAlert} data-testid={testIDs.MEDICAL_ALERT_ICON} />}
    </Flex>
  )
}
