import type { JSXElementConstructor, ReactElement } from 'react'
import { useMemo } from 'react'

import { Box } from '@chakra-ui/react'

import { GenericColumn, StepStylePropGetter } from '../../types/Scheduler'

export type ColumnStepProps<C extends GenericColumn> = {
  stepStylePropGetter?: StepStylePropGetter<C>
  column: C
  start: Date
  isHour: boolean
  stepHeight: number
}

export const ColumnStep = <C extends GenericColumn>({
  start,
  isHour,
  column,
  stepHeight,
  stepStylePropGetter,
}: ColumnStepProps<C>): ReactElement<ColumnStepProps<C>, JSXElementConstructor<HTMLDivElement>> => {
  const sx = useMemo(() => stepStylePropGetter?.(column, start), [column, stepStylePropGetter, start])

  return (
    <Box
      className={isHour ? 'scheduler-chunk-step-hour' : 'scheduler-chunk-step-minute'}
      height={`${stepHeight}px`}
      sx={sx}
    />
  )
}
