import type { FC, ReactElement } from 'react'
import { useEffect, useMemo, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Center, Spinner } from '@chakra-ui/react'

import { useAppState } from '@/app/contexts/app/AppContext'
import { AppActionType } from '@/app/contexts/app/types/AppContext'
import { useUser } from '@/app/contexts/auth/AuthContext'
import { useClinic } from '@/app/hooks/useClinic'

export type RequireClinicProps = {
  children: ReactElement
}

export const RequireClinic: FC<RequireClinicProps> = ({ children }) => {
  const { state, dispatch } = useAppState()
  const location = useLocation()
  const navigate = useNavigate()
  const user = useUser()

  const clinicIdStorage = useRef(state.clinicStorage?.get())

  // Auto-select clinicId if A) clinicStorage is set or B) user have only one clinic:
  const clinicId = useMemo<string | undefined>(() => {
    if (clinicIdStorage.current) {
      return clinicIdStorage.current
    }
    const clinicIds = Object.keys(user?.clinicIdSpecialistIdMap ?? {})
    if (!user || clinicIds.length !== 1) {
      return undefined
    }
    return clinicIds[0]
  }, [user])

  // If no clinicId can be auto-selected, go to Select Form:
  useEffect(() => {
    if (!clinicId) {
      navigate('/select-clinic', { replace: true, state: { from: location } })
    }
  }, [clinicId, location, navigate])

  // Fetch clinic object in order to save in state later:
  const { data, isLoading, isError } = useClinic(clinicId)

  // Save Clinic object in state when loaded:
  useEffect(() => {
    if (data) {
      dispatch({ type: AppActionType.SetClinic, clinic: data })
    }
  }, [data, dispatch])

  // Prevent App render until everything is set:
  if (isError) {
    return <div />
  }
  if (isLoading || !state.clinic) {
    return (
      <Center minH='100vh'>
        <Spinner size='xl' />
      </Center>
    )
  }

  return children
}
