import type { FC } from 'react'
import { createContext, useContext } from 'react'

import { AppEnv } from '@/app/environment/types/AppEnv'

type AppEnvProviderProps = {
  readonly appEnv: AppEnv
}

const AppEnvContext = createContext<AppEnv>({
  env: 'local-dev',
  aws: {
    region: '',
  },
  authentication: {
    userPoolId: '',
    userPoolWebClientId: '',
    oauthDomain: '',
    oauthRedirectSignIn: '',
    oauthRedirectSignOut: '',
  },
  api: {
    baseURL: '',
  },
})

export const AppEnvProvider: FC<AppEnvProviderProps> = ({ appEnv, children }) => {
  if (appEnv.env === 'local-dev') {
    console.log(appEnv)
  }
  return <AppEnvContext.Provider value={appEnv}>{children}</AppEnvContext.Provider>
}

export const useAppEnv = (): AppEnv => useContext(AppEnvContext)
