import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Divider, Text } from '@chakra-ui/react'

import { ScheduleEvent } from '@/app/models/ScheduleEvent'
import { useLabelColor } from '@/ui/common/components/scheduleEventDetails/hooks/useLabelColor'

type NoteDetailsProps = {
  note: ScheduleEvent
}

export const NoteDetails: FC<NoteDetailsProps> = ({ note }) => {
  const labelColor = useLabelColor()
  const { t } = useTranslation('appointmentDetails')
  const formattedHeader = t('comment', { start: note.start.toLocaleTimeString(), end: note.end.toLocaleTimeString() })
  return (
    <Box lineHeight={1.1}>
      <Box mb={5}>
        <Text fontSize='xs' mb={1} fontWeight={700} color={labelColor}>
          {formattedHeader}
        </Text>
      </Box>
      <Divider my={4} />
      <Text fontSize='xs' lineHeight={1.5} fontWeight={400} width='97%' maxWidth='97%'>
        {note.comment}
      </Text>
    </Box>
  )
}
