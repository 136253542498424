const dateTimeFormat = new Intl.DateTimeFormat('fr-CA', {
  hour: 'numeric',
  minute: '2-digit',
  hourCycle: 'h23',
})

export const getHoursMinsFormattedTime = (date: Date): string => {
  const parts = dateTimeFormat
    .formatToParts(date)
    .map(({ type, value: val }) => {
      switch (type) {
        case 'hour':
        case 'minute':
          return val
        default:
          return undefined
      }
    })
    .filter((item) => item)
  return parts.join(':')
}

export const getDateAtStartOfHour = (date: Date): Date => {
  date.setHours(date.getHours() + Math.floor(date.getMinutes() / 60))
  date.setMinutes(0, 0, 0)
  return date
}

export const getDateAtStartOfNextHour = (date: Date): Date => {
  date.setHours(date.getHours() + Math.ceil(date.getMinutes() / 60))
  date.setMinutes(0, 0, 0)
  return date
}
