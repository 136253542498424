import type { FC } from 'react'
import { useRef } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Route, Routes } from 'react-router-dom'

import { AppContextProvider } from '@/app/contexts/app/AppContext'
import { AuthContextProvider } from '@/app/contexts/auth/AuthContext'
import { ClinicLocalStorage } from '@/app/services/ClinicLocalStorage'
import { Error404 } from '@/ui/error404/Error404'
import { useSyncDocumentLocale } from '@/ui/i18n'
import { MainView } from '@/ui/mainView/MainView'
import { PickClinic } from '@/ui/pickClinic/PickClinic'
import { RequireClinic } from '@/ui/requireClinic/RequireClinic'
import { RequireUser } from '@/ui/requireUser/RequireUser'

const clinicLocalStorage = new ClinicLocalStorage(window.sessionStorage)

export const App: FC = () => {
  useSyncDocumentLocale()

  const queryClient = useRef(new QueryClient())

  return (
    <AuthContextProvider>
      <RequireUser>
        <QueryClientProvider client={queryClient.current}>
          <AppContextProvider clinicStorage={clinicLocalStorage}>
            <Routes>
              <Route
                path='/'
                element={
                  <RequireClinic>
                    <MainView />
                  </RequireClinic>
                }
              />
              <Route path='/select-clinic' element={<PickClinic />} />
              <Route path='*' element={<Error404 />} />
            </Routes>
          </AppContextProvider>
        </QueryClientProvider>
      </RequireUser>
    </AuthContextProvider>
  )
}
