import { SystemStyleObject, ThemeComponentProps } from '@chakra-ui/react'

export const AlertsTheme = {
  variants: {
    solid: ({ colorMode, colorScheme }: ThemeComponentProps): SystemStyleObject => {
      const getBgColor = () => {
        switch (colorScheme) {
          case 'red': // Error
            return colorMode === 'light' ? 'red.400' : 'red.500'
          case 'orange': // Warning
            return colorMode === 'light' ? 'orange.300' : 'orange.500'
          case 'green': // Success
            return colorMode === 'light' ? 'teal.300' : 'teal.500'
          case 'blue': // Info
          default:
            return colorMode === 'light' ? 'gray.300' : 'gray.600'
        }
      }

      const getTextColor = () => (colorMode === 'light' ? 'black' : 'white')
      const getIconColor = () => (colorMode === 'light' ? 'gray.700' : 'gray.200')
      const getBlendMode = () => (colorMode === 'light' ? 'multiply' : 'screen')

      return {
        container: {
          bg: getBgColor(),
          color: getIconColor(),
        },
        title: {
          color: getTextColor(),
          blendMode: getBlendMode(),
          opacity: 0.9,
        },
        description: {
          color: getTextColor(),
          fontSize: 'sm',
          blendMode: getBlendMode(),
          opacity: 0.9,
        },
        icon: {
          color: getIconColor(),
          opacity: 0.9,
          blendMode: getBlendMode(),
        },
      }
    },
  },
}
