import type { FC } from 'react'

import { AppointmentEvent } from '@/app/models/ScheduleEvent'
import { IconSvg } from '@/ui/common/components/iconSvg/IconSvg'
import { IconSet } from '@/ui/common/components/iconSvg/types/IconSet.enum'

export type AppointmentStatusProps = {
  confirmationId: AppointmentEvent['confirmationId']
}

export const AppointmentStatus: FC<AppointmentStatusProps> = ({ confirmationId }) => {
  switch (confirmationId) {
    case 1:
      return <IconSvg as={IconSet.Confirmed} />
    case 2:
      return <IconSvg as={IconSet.Pending} />
    case 3:
      return <IconSvg as={IconSet.Declined} />
    case 4:
      return <IconSvg as={IconSet.Email} />
    case 6:
      return <IconSvg as={IconSet.AlternateConfirmed} />
    case 7:
      return <IconSvg as={IconSet.Paid} />
    case 8:
      return <IconSvg as={IconSet.Phone} />
    default:
      return <IconSvg as={IconSet.Unknown} />
  }
}
