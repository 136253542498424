import { useTranslation } from 'react-i18next'
import { useQuery, UseQueryResult } from 'react-query'

import axios, { AxiosError } from 'axios'
import { ClinicDto } from '@dentitek-cloud/shared/dtos/clinic/clinicDto'

import { toClinic } from '@/app/api/mappers/clinic/clinicMappers'
import { useFreshToken } from '@/app/contexts/auth/AuthContext'
import { useAppEnv } from '@/app/environment/AppEnvProvider'
import { useErrorToast } from '@/app/hooks/useErrorToast'
import { Clinic } from '@/app/models/Clinic'

export const useClinic = (clinicId?: Clinic['id']): UseQueryResult<Clinic | undefined> => {
  const { t } = useTranslation()
  const freshTokenGetter = useFreshToken()
  const showErrorToast = useErrorToast()

  const {
    api: { baseURL },
  } = useAppEnv()

  return useQuery(
    'clinic',
    async (): Promise<Clinic | undefined> => {
      if (!clinicId) {
        return undefined
      }
      const token = await freshTokenGetter()

      const httpClient = axios.create({
        baseURL,
      })

      const response = await httpClient.get<ClinicDto>(`/clinics/${clinicId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return toClinic(response.data)
    },
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      onError: (err: AxiosError) => {
        if (err.response?.status === 404) {
          showErrorToast(new Error(t('errors.clinicNotAvailable')))
        } else {
          showErrorToast(err)
        }
      },
    }
  )
}
