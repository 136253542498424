import { Clinic } from '@/app/models/Clinic'
import { ScheduleEvent } from '@/app/models/ScheduleEvent'
import { ClinicLocalStorage } from '@/app/services/ClinicLocalStorage'

export enum AppActionType {
  SetDate = 'SET_DATE',
  SetClinic = 'SET_CLINIC',
  SetScheduleEvent = 'SET_SCHEDULE_EVENT',
  None = 'none',
}

type SetDateAction = {
  type: AppActionType.SetDate
  date: Date
}

type SetClinicAction = {
  type: AppActionType.SetClinic
  clinic?: Clinic
}

type SetScheduleEventAction = {
  type: AppActionType.SetScheduleEvent
  scheduleEvent?: ScheduleEvent
}

type NoAction = {
  type: AppActionType.None
}

export type AppActions = SetDateAction | SetClinicAction | SetScheduleEventAction | NoAction

export type AppState = {
  selectedDate: Date
  clinic?: Clinic
  clinicStorage?: ClinicLocalStorage
  selectedScheduleEvent?: ScheduleEvent
}
