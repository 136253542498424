import type { JSXElementConstructor, ReactElement } from 'react'
import { useCallback, useMemo } from 'react'

import { Box } from '@chakra-ui/react'

import { useUsagesPerStep } from '@/ui/common/components/scheduler/hooks/useUsagesPerStep'

import type { CardStyleProps, GenericCard, GenericColumn, GenericEvent, SchedulerStep } from '../../types/Scheduler'

export type CardRenderer<E extends GenericEvent, C extends GenericColumn> = (
  col: C,
  card: GenericCard<E>,
  position: CardStyleProps
) => ReactElement

export type ColumnEventsProps<E extends GenericEvent, C extends GenericColumn> = {
  min: Date
  max: Date
  step: SchedulerStep
  stepHeight: number
  column: C
  events: E[]
  cardRenderer: CardRenderer<E, C>
}

const SX_BASE = {
  sx: {
    position: 'absolute',
    zIndex: 2,
  },
}

const MINIMAL_STEP = 1

export const ColumnEvents = <E extends GenericEvent, C extends GenericColumn>({
  min,
  max,
  step,
  stepHeight,
  column,
  events,
  cardRenderer,
}: ColumnEventsProps<E, C>): ReactElement<ColumnEventsProps<E, C>, JSXElementConstructor<HTMLDivElement>> => {
  const { cards } = useUsagesPerStep<E>(min, max, events, MINIMAL_STEP)

  const normalizedToMinimalStepHeight = useMemo(() => {
    const factor = MINIMAL_STEP / step
    return stepHeight * factor
  }, [step, stepHeight])

  const getCardPosition = useCallback(
    (card: GenericCard<E>): CardStyleProps => {
      const top = card.topStep * normalizedToMinimalStepHeight + 1
      const height = card.heightSteps * normalizedToMinimalStepHeight - 2

      // card.of is ALWAYS >= 1
      const widthFr = 100 / card.of
      const width = widthFr.toFixed(3).replace('.000', '').concat('%')
      const leftFr = widthFr * (card.position - 1)
      const left = leftFr.toFixed(3).replace('.000', '').concat('%')
      return {
        top: `${top.toFixed(3)}px`,
        height: `${height.toFixed(3)}px`,
        left: `calc(${left} + 3px)`,
        width: `calc(${width} - 6px)`,
        ...SX_BASE,
      }
    },
    [normalizedToMinimalStepHeight]
  )

  return (
    <Box className='scheduler-card-wrapper'>
      {cards.map((card) => cardRenderer(column, card, getCardPosition(card)))}
    </Box>
  )
}
