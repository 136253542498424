import type { JSXElementConstructor, ReactElement } from 'react'

import type { GridProps } from '@chakra-ui/react'
import { Grid, GridItem, Text, useColorModeValue } from '@chakra-ui/react'

import './Scheduler.styles.css'

import { CardRenderer, ColumnEvents } from '@/ui/common/components/scheduler/components/columnEvents/ColumnEvents'
import { ColumnSteps } from '@/ui/common/components/scheduler/components/columnSteps/ColumnSteps'
import { TimeSteps } from '@/ui/common/components/scheduler/components/timeSteps/TimeSteps'
import { useGetEventsCallback } from '@/ui/common/components/scheduler/hooks/useGetEventsCallback'

import type { GenericColumn, GenericEvent, SchedulerStep, StepStylePropGetter } from './types/Scheduler'

type SchedulerGridProps = Omit<
  GridProps,
  'templateColumns' | 'templateRows' | 'gridColumnGap' | 'gridRowGap' | 'className' | 'sx'
>

export type SchedulerProps<E extends GenericEvent, C extends GenericColumn> = SchedulerGridProps & {
  stepHeightPx: number
  step: SchedulerStep
  columns: Array<C>
  start: Date
  end: Date
  events: Map<C['id'], Array<E>>
  cardRenderer?: CardRenderer<E, C>
  stepStylePropGetter?: StepStylePropGetter<C>
}

export const Scheduler = <E extends GenericEvent, C extends GenericColumn>({
  stepHeightPx,
  step,
  columns,
  start,
  end,
  events,
  cardRenderer,
  stepStylePropGetter,
  ...props
}: SchedulerProps<E, C>): ReactElement<SchedulerProps<E, C>, JSXElementConstructor<HTMLDivElement>> => {
  const bgFloatElement = useColorModeValue('whiteAlpha.900', 'blackAlpha.900')

  const getEvents = useGetEventsCallback<E, C>(events)

  return (
    <Grid
      templateColumns={`2.75rem repeat(${columns.length}, var(--scheduler-column-width, 264px))`}
      templateRows='min-content auto'
      gridColumnGap={2}
      gridRowGap={3}
      className='scheduler'
      sx={{ '--scheduler-step-height': `${stepHeightPx}px` }}
      {...props}
    >
      <GridItem
        gridArea='2 / 1 / -1 / 2'
        className='scheduler-time-steps-column'
        pr={1}
        position='sticky'
        left={0}
        bg={bgFloatElement}
        zIndex={9}
      >
        <TimeSteps min={start} max={end} step={step} stepHeight={stepHeightPx} bg={bgFloatElement} />
      </GridItem>

      {columns.map((column, index) => (
        <GridItem
          key={column.id}
          gridArea={`1 / ${index + 2} / 2 / ${index + 3}`}
          mx='1px'
          position='sticky'
          top={0}
          bg={bgFloatElement}
          zIndex={8}
        >
          <Text fontSize='sm' align='center' fontWeight={600}>
            {column.label}
          </Text>
        </GridItem>
      ))}

      {columns.map((column, index) => (
        <GridItem key={column.id} gridArea={`2 / ${index + 2} / 3 / ${index + 3}`} className='scheduler-column'>
          <ColumnSteps<C>
            min={start}
            max={end}
            step={step}
            stepHeight={stepHeightPx}
            column={column}
            stepStylePropGetter={stepStylePropGetter}
          />
          {cardRenderer && (
            <ColumnEvents<E, C>
              min={start}
              max={end}
              step={step}
              stepHeight={stepHeightPx}
              column={column}
              events={getEvents(column.id)}
              cardRenderer={cardRenderer}
            />
          )}
        </GridItem>
      ))}
    </Grid>
  )
}
