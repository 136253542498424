import type { FC } from 'react'
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import _noop from 'lodash/noop'
import { Auth } from '@aws-amplify/auth'
import { EmptyCognitoClinicIdsError } from '@dentitek-cloud/shared/dtos/utils/emptyCognitoClinicIdsError'
import { getClinicIdSpecialistIdMap } from '@dentitek-cloud/shared/dtos/utils/getClinicIdSpecialistIdMap'
import { InvalidCognitoClinicIdsError } from '@dentitek-cloud/shared/dtos/utils/invalidCognitoClinicIdsError'

import { Clinic } from '@/app/models/Clinic'

export type UserInfo = {
  id: string
  username: string
  attributes: {
    sub: string
    name: string
    email: string
    'custom:clinicIds': string
    'custom:clinicIds2': string | undefined
  }
}
export type AppUser = { name: string; email: string; clinicIdSpecialistIdMap?: Record<Clinic['id'], string> }

export type Logout = () => Promise<void>
export type FreshTokenGetter = () => Promise<string>

export type AuthContextValue = {
  user?: AppUser
  signOut: Logout
  freshToken: FreshTokenGetter
}

const useAuth = (): AuthContextValue => {
  const [user, setUser] = useState<AuthContextValue['user']>(undefined)
  const location = useLocation()
  const navigate = useNavigate()

  const navigateToError = useCallback(
    (type: string, clinicIds?: string) => {
      if (location.pathname !== '/404') {
        navigate('/404', { replace: true, state: { from: location, type, clinicIds } })
      }
    },
    [location, navigate]
  )

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const authUser = (await Auth.currentUserInfo()) as UserInfo
        if (authUser) {
          try {
            let clinicIdSpecialistIdMap = getClinicIdSpecialistIdMap(authUser.attributes['custom:clinicIds'])

            // If there are multiple clinicId lists, we put them together
            if (authUser?.attributes['custom:clinicIds2']) {
              clinicIdSpecialistIdMap = {
                ...clinicIdSpecialistIdMap,
                ...getClinicIdSpecialistIdMap(authUser.attributes['custom:clinicIds2']),
              }
            }

            setUser({
              name: authUser.attributes.name,
              email: authUser.attributes.email,
              clinicIdSpecialistIdMap,
            })
          } catch (error) {
            if (error instanceof EmptyCognitoClinicIdsError) {
              setUser({
                name: authUser.attributes.name,
                email: authUser.attributes.email,
              })
              navigateToError(error.message)
            } else if (error instanceof InvalidCognitoClinicIdsError) {
              setUser({
                name: authUser.attributes.name,
                email: authUser.attributes.email,
              })
              navigateToError(error.message, error.clinicIds)
            }
          }
        } else {
          setUser(undefined)
          await Auth.federatedSignIn()
        }
      } catch (error) {
        setUser(undefined)
        await Auth.federatedSignIn()
      }
    }
    // eslint-disable-next-line no-void
    void fetchCurrentUser()
  }, [navigateToError])

  const signOut = useCallback(async () => {
    await Auth.signOut()
    setUser(undefined)
  }, [setUser])

  const freshToken = useCallback(async () => {
    const userSession = await Auth.currentSession()
    if (userSession) {
      return userSession.getIdToken().getJwtToken()
    }
    return ''
  }, [])

  return { user, signOut, freshToken }
}

const AuthContext = createContext<AuthContextValue>({
  signOut: _noop as unknown as Logout,
  freshToken: _noop as unknown as FreshTokenGetter,
})

export const AuthContextProvider: FC = ({ children }) => {
  const { user, signOut, freshToken } = useAuth()
  const values = useMemo(() => ({ user, signOut, freshToken }), [user, signOut, freshToken])
  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export const useUser = (): AuthContextValue['user'] => useContext(AuthContext).user

export const useFreshToken = (): AuthContextValue['freshToken'] => useContext(AuthContext).freshToken

export const useSignOut = (): AuthContextValue['signOut'] => useContext(AuthContext).signOut
