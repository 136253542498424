import type { FC } from 'react'

import type { TooltipProps } from '@chakra-ui/react'
import { Tooltip } from '@chakra-ui/react'

import { TOOLTIP_BG_COLOR } from '@/ui/theme/tooltips.theme'

export type TooltipWithArrowProps = Omit<TooltipProps, 'hasArrow' | 'bg' | 'borderRadius'>

export const TooltipWithArrow: FC<TooltipWithArrowProps> = ({ children, ...props }) => (
  <Tooltip hasArrow bg={TOOLTIP_BG_COLOR} borderRadius='base' {...props}>
    {children}
  </Tooltip>
)
