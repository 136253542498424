import dayjs from 'dayjs'
import { TimeString } from '@dentitek-cloud/shared/dtos/time/types/timeString'

/**
 * @return {Date}
 */
export const getDateNow = (): Date => new Date()

/**
 * @param {Date} date
 * @param {string} locale
 * @return {string}
 */
export function formatReadableDate(date: Date, locale = 'fr-CA'): string {
  const formatter = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
  return formatter.format(date)
}

/**
 * @param {Date} date
 * @param {string} locale
 * @return {string}
 */
export function formatTwoDigitsDate(date: Date, locale: string): string {
  const formatter = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })

  return formatter.format(date)
}

/**
 * @param {Date} date
 * @return {string}
 */
export function formatTwoDigitsDateWithHyphen(date: Date): string {
  return dayjs(date).format('YYYY-MM-DD')
}

/**
 * @param {Date} date
 * @return {string}
 */
export function hashDate(date: Date): string {
  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()
  return `${year}/${month}/${day}`
}

export const timeStringToDate = (time: TimeString, date: Date): Date => {
  const dateCopy = new Date(date.getTime())
  const DELIMITER = ':'
  const [hours, minutes] = time.split(DELIMITER)

  dateCopy.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0)

  return dateCopy
}
