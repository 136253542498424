import { Clinic } from '@/app/models/Clinic'

export const SELECTED_CLINIC_ID_STORAGE_KEY = 'selected-clinic-id'

export type PartialStorage = {
  getItem(key: string): string | null
  removeItem(key: string): void
  setItem(key: string, value: string): void
}

export class ClinicLocalStorage {
  private readonly storage: PartialStorage

  constructor(storage: PartialStorage) {
    this.storage = storage
  }

  public set(clinicId?: Clinic['id']): void {
    if (clinicId) {
      this.storage.setItem(SELECTED_CLINIC_ID_STORAGE_KEY, clinicId)
    } else {
      this.storage.removeItem(SELECTED_CLINIC_ID_STORAGE_KEY)
    }
  }

  public get(): Clinic['id'] {
    return this.storage.getItem(SELECTED_CLINIC_ID_STORAGE_KEY) ?? ''
  }
}
