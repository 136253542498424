import type { UseToastOptions } from '@chakra-ui/react'
import { useToast } from '@chakra-ui/react'

export const useNotification = (options?: UseToastOptions): ReturnType<typeof useToast> => {
  const defaultOptions: UseToastOptions = {
    status: 'error',
    variant: 'solid',
    position: 'bottom-start',
    duration: null,
    isClosable: true,
  }

  return useToast({
    ...defaultOptions,
    ...options,
  })
}
