import type { FC } from 'react'

import { Flex, Text } from '@chakra-ui/react'

import { DASH } from '@/app/helpers/types/dash'

import { useLabelColor } from '../hooks/useLabelColor'
import { useValueColor } from '../hooks/useValueColor'
import { VisibilityLevel } from '../types/AppointmentDetails'

export type LabelValueRowProps = {
  label: string
  value?: string
  visibilityLevel?: VisibilityLevel
}

const EMPTY_VALUE_SYMBOL = DASH.EM

export const LabelValueRow: FC<LabelValueRowProps> = ({ label, value, visibilityLevel = VisibilityLevel.Standard }) => {
  const labelColor = useLabelColor()
  const valueColor = useValueColor(value ?? '', visibilityLevel)

  return (
    <Flex justify='space-between' lineHeight={1}>
      <Text fontSize='xs' fontWeight={700} isTruncated color={labelColor} maxWidth='35%'>
        {label}
      </Text>
      <Text
        color={valueColor}
        fontSize='xs'
        fontWeight={visibilityLevel === 'alert' ? 700 : 400}
        width='62%'
        maxWidth='62%'
      >
        {value || EMPTY_VALUE_SYMBOL}
      </Text>
    </Flex>
  )
}
