import { useMemo } from 'react'

import { formatReadableDate } from '@/app/helpers/date'

export const useToday = (locale: string): [Date, string] => {
  const today = useMemo(() => new Date(), [])
  const readableToday = useMemo(() => formatReadableDate(today, locale), [locale, today])

  return [today, readableToday]
}
