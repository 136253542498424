/* istanbul ignore file */
import { Auth } from '@aws-amplify/auth'

import { AppEnv } from '@/app/environment/types/AppEnv'

export const initAmplify = (aws: AppEnv['aws'], authentication: AppEnv['authentication']): void => {
  Auth.configure({
    region: aws.region,
    userPoolId: authentication.userPoolId,
    userPoolWebClientId: authentication.userPoolWebClientId,
    authenticationFlowType: 'USER_SRP_AUTH',
    oauth: {
      domain: authentication.oauthDomain,
      scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
      redirectSignIn: authentication.oauthRedirectSignIn,
      redirectSignOut: authentication.oauthRedirectSignOut,
      responseType: 'code',
    },
  })
}
