import { SPACE } from '@/app/helpers/types/space'

export const common = {
  login: 'Connexion',
  logout: 'Déconnexion',
  pickClinic: 'Sélectionner votre clinique',
  select: 'Sélectionner',
  errorTitle: 'Erreur.',
  errorUnknown: 'Erreur inconnue.',
  error404: 'Ressource introuvable.',
  errors: {
    emptyClinicIds: 'Vous n’êtes assigné à aucune clinique.',
    invalidClinicIds_one: `Information erronée sur la clinique${SPACE.NNBSP}: {{ids}}`,
    invalidClinicIds_other: `Information erronée sur les cliniques${SPACE.NNBSP}: {{ids}}`,
    invalidClinicIdsUnknown: 'Information erronée sur les cliniques',
    clinicNotAvailable:
      "La synchronisation initiale des données est en cours et peut prendre jusqu'à 24 heures. Veuillez réessayer plus tard.",
  },
}
