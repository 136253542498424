export const common = {
  login: 'Login',
  logout: 'Logout',
  pickClinic: 'Select your clinic',
  select: 'Select',
  errorTitle: 'Error.',
  errorUnknown: 'Unknown Error.',
  error404: 'Resource not found.',
  errors: {
    emptyClinicIds: 'You are not assigned to any clinic.',
    invalidClinicIds_one: 'Wrong information about the clinic: {{ids}}',
    invalidClinicIds_other: 'Incorrect information about clinics: {{ids}}',
    invalidClinicIdsUnknown: 'Incorrect information about clinics',
    clinicNotAvailable:
      'Initial data synchronization is in progress and may take up to 24 hours. Please try again later.',
  },
}
