import type { FC } from 'react'

import { useColorModeValue } from '@chakra-ui/react'

import { ReactComponent as DentitekHoraireLogo } from '@/ui/assets/dentitek-horaire.svg'

export type DentitekLogoProps = {
  scale?: number
  translateY?: string
}

export const DentitekLogo: FC<DentitekLogoProps> = ({ scale = 1, translateY = '0' }) => {
  const currentColor = useColorModeValue('gray.800', 'gray.100')

  return (
    <DentitekHoraireLogo
      data-testid='dentitek-logo'
      color={currentColor}
      style={{ transform: `scale(${scale}) translateY(${translateY})`, minHeight: '1.5rem' }}
    />
  )
}
