import { FC, ReactElement } from 'react'

import { Center, Spinner } from '@chakra-ui/react'

import { useUser } from '@/app/contexts/auth/AuthContext'

export type RequireUserProps = {
  children: ReactElement
}

export const RequireUser: FC<RequireUserProps> = ({ children }) => {
  const user = useUser()

  if (!user) {
    return (
      <Center minH='100vh'>
        <Spinner size='xl' />
      </Center>
    )
  }

  return children
}
