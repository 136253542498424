import { FC } from 'react'

import { ReactComponent as EmailIcon } from '@/ui/assets/icons/email.svg'
import { ReactComponent as AlternateConfirmedIcon } from '@/ui/assets/icons/green-invert-round-check.svg'
import { ReactComponent as ConfirmedIcon } from '@/ui/assets/icons/green-round-check.svg'
import { ReactComponent as LogoutIcon } from '@/ui/assets/icons/logout.svg'
import { ReactComponent as MedicalAlertIcon } from '@/ui/assets/icons/medical-alert.svg'
import { ReactComponent as PaidIcon } from '@/ui/assets/icons/paid.svg'
import { ReactComponent as PhoneIcon } from '@/ui/assets/icons/phone.svg'
import { ReactComponent as DeclinedIcon } from '@/ui/assets/icons/red-round-check.svg'
import { ReactComponent as UnknownIcon } from '@/ui/assets/icons/unknown.svg'
import { ReactComponent as PendingIcon } from '@/ui/assets/icons/yellow-round-check.svg'

import { IconSet } from './types/IconSet.enum'

export type IconSvgProps = {
  as: IconSet
}

export const IconSvg: FC<IconSvgProps> = ({ as }) => {
  switch (as) {
    case IconSet.MedicalAlert:
      return <MedicalAlertIcon data-testid='IconMedicalAlert' />
    case IconSet.Pending:
      return <PendingIcon data-testid='IconPending' />
    case IconSet.Confirmed:
      return <ConfirmedIcon data-testid='IconConfirmed' />
    case IconSet.Declined:
      return <DeclinedIcon data-testid='IconDeclined' />
    case IconSet.Email:
      return <EmailIcon data-testid='IconEmail' />
    case IconSet.Logout:
      return <LogoutIcon data-testid='IconLogout' />
    case IconSet.AlternateConfirmed:
      return <AlternateConfirmedIcon data-testid='IconAlternateConfirmed' />
    case IconSet.Paid:
      return <PaidIcon data-testid='IconPaid' />
    case IconSet.Phone:
      return <PhoneIcon data-testid='IconPhone' />
    case IconSet.Unknown:
    default:
      return <UnknownIcon data-testid='IconUnknown' />
  }
}
