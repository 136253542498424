import { SystemStyleObject, ThemeComponentProps } from '@chakra-ui/react'

import { fontFamily } from '@/ui/theme/font-family'

export enum ButtonVariants {
  link = 'link',
  solid = 'solid',
  outline = 'outline',
}

export const ButtonTheme = {
  baseStyle: {
    borderRadius: 'md',
    fontFamily,
    fontWeight: 600,
  },
  sizes: {
    xs: {
      fontSize: '0.75rem',
    },
    sm: {
      fontSize: '0.875rem',
    },
    md: {
      fontSize: '1rem',
    },
    lg: {
      fontSize: '1.125rem',
    },
  },
  variants: {
    [ButtonVariants.solid]: ({ colorMode }: ThemeComponentProps): SystemStyleObject => {
      const hoverColor = colorMode === 'light' ? `pink.400` : `pink.600`
      const activeColor = colorMode === 'light' ? `pink.600` : `pink.300`
      const disabledBgColor = colorMode === 'light' ? `gray.100` : `gray.800`
      const disabledColor = colorMode === 'light' ? `gray.400` : `gray.500`

      const disabled = {
        bg: disabledBgColor,
        borderColor: disabledBgColor,
        color: disabledColor,
        opacity: 1,
      }

      return {
        border: '1px solid',
        bg: 'pink.500',
        borderColor: 'pink.500',
        color: 'white',
        _disabled: disabled,
        _active: {
          bg: activeColor,
          borderColor: activeColor,
          color: 'gray.50',
        },
        _hover: {
          bg: hoverColor,
          borderColor: hoverColor,
          color: 'gray.50',
          _disabled: disabled,
        },
      }
    },
    [ButtonVariants.outline]: ({ colorMode }: ThemeComponentProps): SystemStyleObject => {
      const bgColor = 'transparent'
      const borderColor = colorMode === 'light' ? `gray.200` : `gray.700`
      const hoverBgColor = colorMode === 'light' ? `gray.50` : `gray.900`
      const hoverBorderColor = colorMode === 'light' ? `gray.300` : `gray.600`
      const textColor = colorMode === 'light' ? `gray.900` : `gray.200`
      const activeColor = colorMode === 'light' ? `pink.500` : `pink.600`
      const disabledBgColor = colorMode === 'light' ? `gray.100` : `gray.800`
      const disabledColor = colorMode === 'light' ? `gray.400` : `gray.500`

      const disabled = {
        bg: bgColor,
        borderColor: disabledBgColor,
        color: disabledColor,
        opacity: 1,
      }

      return {
        border: '1px solid',
        bg: bgColor,
        borderColor,
        color: textColor,
        _disabled: disabled,
        _active: {
          borderColor: activeColor,
          color: activeColor,
        },
        _hover: {
          bg: hoverBgColor,
          borderColor: hoverBorderColor,
          color: textColor,
          _disabled: disabled,
        },
      }
    },
    [ButtonVariants.link]: ({ colorMode }: ThemeComponentProps): SystemStyleObject => {
      const disabledColor = colorMode === 'light' ? `gray.300` : `gray.600`
      const mainColor = colorMode === 'light' ? `pink.500` : `pink.300`

      return {
        px: 0,
        height: 'auto',
        minHeight: 0,
        minWidth: 0,
        bg: 'transparent',
        border: '1px solid',
        borderColor: 'transparent',
        borderWidth: 0,
        borderRadius: 'sm',
        color: mainColor,
        _disabled: {
          color: disabledColor,
          opacity: 1,
        },
        _active: {
          color: mainColor,
        },
        _hover: {
          color: mainColor,
          _disabled: {
            color: disabledColor,
          },
        },
      }
    },
  },
}
