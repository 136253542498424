import type { FC } from 'react'
import { useMemo } from 'react'

import classNames from 'classnames'
import type { BoxProps } from '@chakra-ui/react'
import { Box, Flex, Text } from '@chakra-ui/react'

import './AppointmentCard.styles.css'

import { useAppState } from '@/app/contexts/app/AppContext'
import { AppActionType } from '@/app/contexts/app/types/AppContext'
import { AppointmentEvent } from '@/app/models/ScheduleEvent'
import { AppointmentStatus } from '@/ui/common/components/appointmentCard/components/appointmentStatus/AppointmentStatus'
import { IconSvg } from '@/ui/common/components/iconSvg/IconSvg'
import { IconSet } from '@/ui/common/components/iconSvg/types/IconSet.enum'
import { TooltipWithArrow } from '@/ui/common/components/tooltip/TooltipWithArrow'

export type AppointmentCardProps = Omit<BoxProps, 'backgroundColor' | 'onClick'> & {
  appointment: AppointmentEvent
}

export const AppointmentCard: FC<AppointmentCardProps> = ({ appointment, ...props }) => {
  const { dispatch, state } = useAppState()
  const treatmentLabel = useMemo(() => {
    if (appointment.comment) {
      return appointment.treatmentType.concat(',')
    }
    return appointment.treatmentType
  }, [appointment.treatmentType, appointment.comment])

  const backgroundColor = useMemo(() => appointment.style?.backgroundColor ?? 'gray.500', [appointment.style])

  const fullName = useMemo(
    () => [appointment.patient.lastName, appointment.patient.firstName].join(', '),
    [appointment.patient]
  )

  const tooltip = useMemo(
    () => (
      <Text as='small'>
        {fullName}, ({appointment.patient.id})
        <br />
        {treatmentLabel} {appointment.comment}
      </Text>
    ),
    [appointment.comment, appointment.patient.id, fullName, treatmentLabel]
  )

  const isSelected = useMemo(
    () => state.selectedScheduleEvent?.id === appointment.id,
    [appointment.id, state.selectedScheduleEvent]
  )

  return (
    <Box
      className={classNames('appointment-card', { 'appointment-card--selected': isSelected })}
      backgroundColor={backgroundColor}
      {...props}
      onClick={() => dispatch({ type: AppActionType.SetScheduleEvent, scheduleEvent: appointment })}
    >
      <Box className='appointment-card--subtype' backgroundColor={appointment.style?.bandColor ?? backgroundColor} />
      <TooltipWithArrow label={tooltip} openDelay={500} placement='top' lineHeight={1.15} pt={1} pb={2}>
        <Flex className='appointment-card--content' justify='space-between' gap={1}>
          <Box flex={0}>
            {!!appointment.confirmationId && <AppointmentStatus confirmationId={appointment.confirmationId} />}
          </Box>

          <Box minW={0} flex={1}>
            <Text fontSize='xs' fontWeight={600} isTruncated>
              {fullName}{' '}
              <Text as='span' fontWeight={500} color='gray.600'>
                ({appointment.patient.id})
              </Text>
            </Text>
            <Flex wrap='wrap' justify='flex-start'>
              <Text fontSize='xs' isTruncated className='appointment-card--treatment'>
                {treatmentLabel}
              </Text>
              {appointment.comment && (
                <Text fontSize='xs' isTruncated>
                  {appointment.comment}
                </Text>
              )}
            </Flex>
          </Box>

          {appointment.patient.medicalAlert !== '' && (
            <Box flex='0 1'>
              <IconSvg as={IconSet.MedicalAlert} />
            </Box>
          )}
        </Flex>
      </TooltipWithArrow>
    </Box>
  )
}
